import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'path-to-regexp';
import Menu from '../../component/Menu/Menu';
import { Project } from '../../helper/type';
import hirerRoutes from '../../route/hirer.route';
import { HIRER_ROUTES } from '../../route/constant';
import { useJobService } from '../../service/job.service';
import { useRatingService } from '../../service/rating.service';
import { openModal, setMenuMode } from '../../store/app.slice';
import { setPendingRatings, setProjects } from '../../store/hirer.slice';
import { RootState } from '../../store/store';
import { BottomNavigation, Div } from '../../framework';
import { featureToggle } from '../../../config/app.config';

import './Base.scss';

const HirerBasePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const jobService = useJobService();
  const ratingService = useRatingService();
  const { authHirer } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  const matchPath = (routePattern: string, path: string) =>
    match(routePattern, { decode: decodeURIComponent })(path);
  useEffect(() => {
    const currentPath = history.location.pathname;

    const requireLogin = hirerRoutes.find((route) =>
      matchPath(route.path as string, currentPath)
    )?.requireLogin;

    const impkey = new URLSearchParams(search).get('impkey');
    if (requireLogin || impkey) {
      history.push(HIRER_ROUTES.AUTH + search, {
        nextRoute: currentPath + search,
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (authHirer.hirerId) {
        const projects: Project[] = await jobService.getProjectsByHirer(
          authHirer.hirerId
        );
        dispatch(setProjects(projects));
      }
    })();
  }, [authHirer.hirerId]);

  useEffect(() => {
    (async () => {
      if (!authHirer.hirerId || !featureToggle.rating) return;

      const { data } = await ratingService.listPendingToRate(authHirer.hirerId);
      dispatch(setPendingRatings(data));
      if (data.length > 0)
        dispatch(openModal({ modal: 'prompt-hirer-rate-talent' }));
    })();
  }, [authHirer.hirerId]);

  return (
    <Div className='page-hirer-base'>
      <Div className='content'>
        <Menu />
        <Switch>
          {hirerRoutes.map((route, key) => (
            <Route key={key} {...route} />
          ))}
        </Switch>
        {authHirer.hirerId && <BottomNavigation type='hirer' />}
      </Div>
    </Div>
  );
};

export default HirerBasePage;

import React, { FunctionComponent, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { Page } from '../../../framework';
import { HirerCompany, HirerUser } from '../../../helper/type';
import { HIRER_ROUTES } from '../../../route/constant';
import { useSstAuthService } from '../../../service/auth.sst.service';
import { useHirerService } from '../../../service/hirer.service';
import {
  clearCompanyProfile,
  setCompanyProfile,
} from '../../../store/hirer.slice';
import { clearAuthHirer, setAuthHirer } from '../../../store/user.slice';

const HirerAuthPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state: locationState } = useLocation<any>();
  const hirerService = useHirerService();
  const sstAuthService = useSstAuthService();
  const { mode } = useParams<{ mode?: 'new' }>();

  const getCompanyProfile = async (hirerId: string) => {
    try {
      return await hirerService.getHirer(hirerId);
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data?.code === 'NOT_FOUND') return;

      throw error;
    }
  };

  const handleNewUserAuth = async (user: HirerUser, company?: HirerCompany) => {
    const verifiedUser = await hirerService.updateUser({
      hirerId: user.hirerId,
      userId: user.userId,
      status: 'verified',
    });
    dispatch(setAuthHirer(verifiedUser));

    if (company) {
      dispatch(setCompanyProfile(company));
      history.replace(HIRER_ROUTES.DASHBOARD);
    } else {
      const registerState = new URLSearchParams(search).get('state');
      history.replace(
        HIRER_ROUTES.REGISTER_COMPANY,
        registerState ? JSON.parse(registerState) : undefined
      );
    }
  };

  const handleLoginAuth = async (company?: HirerCompany) => {
    if (company) {
      dispatch(setCompanyProfile(company));
      history.replace(locationState?.nextRoute ?? HIRER_ROUTES.DASHBOARD);
    } else {
      history.replace(HIRER_ROUTES.REGISTER_COMPANY);
    }
  };

  const handleImpersonateAuth = async () => {
    const searchParams = new URLSearchParams(search);
    const hirerId = searchParams.get('imphid');
    if (!hirerId) {
      alert('Invalid hirerId');
      history.replace('/');
      return;
    }

    let userId = searchParams.get('impuid');
    if (!userId) {
      const users = await hirerService.listHirerUsers(hirerId);
      userId = users[0].userId;
    }

    await sstAuthService.impAuth({
      impkey: searchParams.get('impkey') as string,
      data: { hirerId, userId },
    });
  };

  useEffect(() => {
    (async () => {
      let isImpersonator = false;
      if (new URLSearchParams(search).get('impkey')) {
        await handleImpersonateAuth();
        isImpersonator = true;
      }

      try {
        const user = await hirerService.getUser();
        dispatch(setAuthHirer({ ...user, isImpersonator }));

        const hirerCompany = await getCompanyProfile(user.hirerId);
        if (mode === 'new') {
          await handleNewUserAuth(user, hirerCompany);
        } else {
          await handleLoginAuth(hirerCompany);
        }
      } catch (error) {
        const e = error as AxiosError;
        message.error(e.response?.data?.message);
        dispatch(clearAuthHirer());
        dispatch(clearCompanyProfile());
        history.replace(HIRER_ROUTES.LOGIN);
      }
    })();
  }, []);

  return <Page className='page-login' />;
};

export default HirerAuthPage;

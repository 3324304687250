import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Div } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { HIRER_ROUTES } from '../../../route/constant';
import { setFlyoutOpen, setMenuMode } from '../../../store/app.slice';
import AgendaTab from './AgendaTab';

import './Agenda.scss';

const AgendaPage = () => {
  useResetScroll();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  return (
    <Div className='page-hirer-agenda'>
      <Helmet>
        <title>Agenda - HeyCast.Me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={HIRER_ROUTES.DASHBOARD}>Dashboard</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Agenda</Breadcrumb.Item>
      </Breadcrumb>

      <Div flex className='title-container'>
        <Title level={4}>Agenda</Title>
      </Div>
      <Tabs
        defaultActiveKey='upcoming'
        centered
        items={[
          {
            key: 'upcoming',
            label: 'Upcoming',
            children: <AgendaTab type='upcoming' />,
          },
          {
            key: 'past',
            label: 'Past',
            children: <AgendaTab type='past' />,
          },
        ]}
      ></Tabs>
    </Div>
  );
};

export default AgendaPage;

import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Talent } from '../../helper/type';
import authRoutes from '../../route/auth.route';
import { useTalentService } from '../../service/talent.service';
import { useSettingsService } from '../../service/settings.service';
import { RootState } from '../../store/store';
import {
  setIsTalentOnboarded,
  setPartialTalentProfile,
  setProfilePicture,
  setPartialSettings,
} from '../../store/user.slice';

const Auth: FunctionComponent = () => {
  const { authUser, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const history = useHistory();
  const location = useLocation();
  const talentService = useTalentService();
  const settingsService = useSettingsService();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      let isLastStepFilled = false;

      if (
        !location.pathname.includes('logout') &&
        !location.pathname.includes('verify-account') &&
        (authUser.username || impersonator.impersonateId)
      ) {
        const talentId = authUser.username || impersonator.impersonateId;
        // TODO(vincenthongzy): streamlined endpoint to get isOnboarded
        const data: Talent = await talentService.getSelfProfile(talentId);
        const { minimumDailyWage, acceptRoles, profilePhotoThumbnail } = data;
        isLastStepFilled =
          acceptRoles &&
          acceptRoles.length > 0 &&
          minimumDailyWage !== undefined;

        const settingsData = await settingsService.getSettings();

        dispatch(setPartialTalentProfile(data));
        dispatch(setIsTalentOnboarded({ isOnboarded: isLastStepFilled }));
        dispatch(
          setProfilePicture({ profilePictureUrl: profilePhotoThumbnail })
        );
        dispatch(setPartialSettings(settingsData));
        redirectAfterLogin(isLastStepFilled);
      }
    })();
  }, [authUser, impersonator]);

  const redirectAfterLogin = (isOnboarded: boolean) => {
    const redirect = sessionStorage.getItem('redirect');
    sessionStorage.removeItem('redirect');

    if (authUser.type !== 'talent') {
      history.push('/dashboard');
      return;
    }
    if (!isOnboarded) {
      history.push('/talent/profile/contact');
      return;
    }

    history.push(redirect ?? '/talent/jobs');
  };

  return (
    <Switch>
      {authRoutes.map((props, key) => (
        <Route key={key} {...props} />
      ))}
    </Switch>
  );
};

export default Auth;

import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { clearError } from '../../../store/app.slice';
import { Page } from '../../../framework';
import PasswordHint from '../../../component/PasswordHint/PasswordHint';
import { passwordRules } from '../../../helper/constants';
import { Div } from '../../../framework/Div/Div';
import { useAuthService } from '../../../service/auth.service';
import { useTalentService } from '../../../service/talent.service';
import Menu from '../../../component/Menu/Menu';
import RequestError from '../../../component/RequestError/RequestError';
import { Talent } from '../../../helper/type';
import { AUTH_ROUTES } from 'client/src/route/constant';

import './ClaimProfile.scss';

const { Title, Paragraph } = Typography;

const ClaimProfilePage: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authService = useAuthService();
  const talentService = useTalentService();
  const { error } = useSelector((state: RootState) => state.app);
  const [talent, setTalent] = useState<Talent>();
  const { search } = useLocation();
  const talentId = new URLSearchParams(search).get('id') as string;
  const code = new URLSearchParams(search).get('v') as string;

  // useEffect(() => {
  //   dispatch(setMenuMode({ isShrank: true }));
  // }, []);

  useEffect(() => {
    (async () => {
      const data = await talentService.getProfile(talentId);
      setTalent(data);
    })();

    return () => {
      dispatch(clearError({ requestKey: 'talent/profile/get' }));
    };
  }, []);

  const onFinish = async (values: any) => {
    await authService.claimProfile({
      ...values,
      verificationCode: code,
      username: talentId,
    });
    history.push(`${AUTH_ROUTES.TALENT_LOGIN}?showMessage=true`);
  };

  const renderError = () => {
    return (
      <Div mv='m'>
        <Title level={3}>Invalid Link</Title>
        <Paragraph>
          The link seems to be invalid or expired. If you already have an
          account, proceed to <Link to={AUTH_ROUTES.TALENT_LOGIN}>login</Link>.
        </Paragraph>
        <Paragraph>
          No registered yet?{' '}
          <Link to='/auth/talent/register'>Create a talent profile</Link>
        </Paragraph>
      </Div>
    );
  };

  const renderForm = () => {
    return (
      <Form
        style={{ width: '100%' }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Title level={3}>Claim talent profile</Title>
        <Paragraph>
          You are about to claim talent profile for{' '}
          <Link to={`/t/${talentId}`}>{talent?.name}</Link>. If you are not
          intended to claim this profile, leave this page immediately.
        </Paragraph>
        <Paragraph>
          By claiming profile, you would link the said profile with your email.
          Please enter the email you would like link this profile with.
        </Paragraph>
        <Form.Item
          name='email'
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
            {
              type: 'email',
              message: 'This is not a valid email',
            },
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder='Email' />
        </Form.Item>
        <Form.Item
          name='password'
          hasFeedback
          validateFirst
          rules={passwordRules}
        >
          <Input
            prefix={<LockOutlined />}
            type='password'
            placeholder='Password'
          />
        </Form.Item>
        <PasswordHint />
        <Form.Item>
          <Button type='primary' htmlType='submit' block>
            Sign Up
          </Button>
        </Form.Item>
        <RequestError requestKey='auth/claim' />
      </Form>
    );
  };

  return (
    <Page className='page-register'>
      <Helmet>
        <title>Claim Profile</title>
        <meta name='description' content='Claim Talent Profile' />
      </Helmet>
      <Div className='mobile-view'>
        <Menu />
        {talentId &&
          code &&
          talent &&
          !error['talent/profile/get'] &&
          !talent?.claimed &&
          renderForm()}
        {(!talentId ||
          !code ||
          error['talent/profile/get'] ||
          talent?.claimed) &&
          renderError()}
      </Div>
    </Page>
  );
};

export default ClaimProfilePage;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Breadcrumb, Form, Input, Button, message } from 'antd';
import { AxiosError } from 'axios';
import { Div, Page } from '../../framework';
import { useResetScroll } from '../../helper/hook';
import { useSettingsService } from '../../service/settings.service';

import './Announcement.scss';

const { Title } = Typography;

const Announcement = () => {
  useResetScroll();
  const { search } = useLocation();
  const settingsService = useSettingsService();
  const imkey = new URLSearchParams(search).get('imkey') as string;

  const [form] = Form.useForm();

  const onFinish = async (values: { message: string }) => {
    try {
      await settingsService.broadcastTelegram(imkey, values.message);
      form.resetFields();
    } catch (err) {
      const error = err as AxiosError;
      const errorData = error.response?.data;
      if (errorData?.code === 'UNAUTHORIZED_EXCEPTION') {
        message.error(errorData?.message, 10);
      }
    }
  };

  return (
    <Page className='page-admin-announcement'>
      <Div className='mobile-view'>
        <Breadcrumb>
          <Breadcrumb.Item>Announcement</Breadcrumb.Item>
        </Breadcrumb>
        <Div flex className='title-container'>
          <Title level={4}>Broadcast Announcement</Title>
        </Div>
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <Form.Item
            label='Message'
            name='message'
            rules={[
              { required: true, message: 'Please input announcement message.' },
            ]}
          >
            <Input.TextArea
              rows={5}
              showCount
              placeholder='New feature/Maintenance/Downtime announcement that will broadcast to all talents who subscribed telegram.'
            />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              block
              className='btn-submit'
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Div>
    </Page>
  );
};

export default Announcement;

import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { AUTH_ROUTES } from 'client/src/route/constant';
import { Page } from '../../../framework';

const WhichUserPage: FunctionComponent = () => {
  return (
    <Page align='center'>
      <Button href='/auth/hirer/login'>I want to hire talents</Button>
      <Button href={AUTH_ROUTES.TALENT_LOGIN}>I want to apply for jobs</Button>
    </Page>
  );
};

export default WhichUserPage;

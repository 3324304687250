import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Menu from '../../component/Menu/Menu';
import Footer from '../../component/Footer/Footer';
import { Div } from '../../framework';
import { isClient } from '../../helper/detect';
import adminRoutes from '../../route/admin.route';
import { setMenuMode } from '../../store/app.slice';

import './Base.scss';

const AdminBasePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const imkey =
    new URLSearchParams(search).get('imkey') ||
    (isClient && localStorage.getItem('imkey'));

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    if (imkey) {
      localStorage.setItem('imkey', imkey);
    } else {
      history.push('/');
    }
  }, []);

  return (
    <Div className='page-admin-base'>
      <Div className='content'>
        <Menu />
        <Switch>
          {adminRoutes.map((props, key) => (
            <Route key={key} {...props} />
          ))}
        </Switch>
        <Footer />
      </Div>
    </Div>
  );
};

export default AdminBasePage;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Page, Div } from '../../../framework';
import { useAuthService } from '../../../service/auth.service';
import Menu from '../../../component/Menu/Menu';
import { AUTH_ROUTES, TALENT_ROUTES } from 'client/src/route/constant';
import { RootState } from '../../../store/store';
import { setAuthUser } from '../../../store/user.slice';

import './VerifyAccount.scss';

const VerifyAccountPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const authService = useAuthService();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code') as string;
  const userId = new URLSearchParams(search).get('userId') as string;
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsfailure] = useState(false);
  const { userType } = useParams<{ userType: 'talent' | 'hirer' }>();
  const { authUser } = useSelector((state: RootState) => state.user);
  const hasSignedIn = Boolean(authUser.id);

  useEffect(() => {
    (async () => {
      try {
        await authService.verifySignup({ code, userId }, userType);
        setIsSuccess(true);
      } catch (err) {
        setIsfailure(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (isSuccess && hasSignedIn && !authUser.verified) {
      dispatch(
        setAuthUser({
          ...authUser,
          verified: true,
        })
      );
    }
  }, [isSuccess, authUser]);

  const renderRedirectButton = () => {
    if (hasSignedIn) {
      return (
        <Link to={TALENT_ROUTES.DASHBOARD} key='console'>
          <Button type='primary'>Back to Dashboard</Button>
        </Link>
      );
    }

    return (
      <Link to={AUTH_ROUTES.TALENT_LOGIN} key='console'>
        <Button type='primary'>Proceed to Login</Button>
      </Link>
    );
  };

  const renderSuccess = () => {
    return (
      <Result
        status='success'
        title='Congratulations, account verified!'
        subTitle='You have access to apply for posted roles'
        extra={[renderRedirectButton()]}
      />
    );
  };

  const renderFailure = () => {
    return (
      <Result
        title='Verification link expired'
        subTitle='The link might have expired, try to login'
        extra={[renderRedirectButton()]}
      />
    );
  };

  return (
    <Page className='page-verify-account'>
      <Helmet>
        <title>Account Verification</title>
        <meta name='description' content='account verification page' />
      </Helmet>
      <Div className='mobile-view'>
        <Menu />
        {isSuccess ? renderSuccess() : null}
        {isFailure ? renderFailure() : null}
      </Div>
    </Page>
  );
};

export default VerifyAccountPage;

import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Empty, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { Div, InfiniteScroll } from '../../../framework';
import { Activity } from '../../../helper/type';
import { useActivityService } from '../../../service/activity.service';
import { useJobService } from '../../../service/job.service';
import { useTalentService } from '../../../service/talent.service';
import { RootState } from '../../../store/store';
import AgendaCard from './AgendaCard';

import './AgendaTab.scss';

type Props = {
  type: 'upcoming' | 'past';
};

type TalentInfo = {
  id: string;
  name: string;
  dob: string;
  profilePhotoThumbnail: string;
};

const AgendaTab: FunctionComponent<Props> = ({ type }) => {
  const activityService = useActivityService();
  const jobsService = useJobService();
  const talentService = useTalentService();
  const { authHirer } = useSelector((state: RootState) => state.user);

  const [data, setData] = useState<Activity[]>([]);
  const [mappedTalents, setMappedTalents] = useState<
    Record<string, TalentInfo>
  >({});
  const [projectTitles, setProjectTitles] = useState<Record<string, string>>(
    {}
  );
  const [roleNames, setRoleNames] = useState<Record<string, string>>({});
  const [dataKey, setDataKey] = useState<string>();

  const loadTalentInfo = async (talentIds: string[]) => {
    if (talentIds.length === 0) return;

    const result: TalentInfo[] = await talentService.batchGetTalent({
      talentIds,
    });
    setMappedTalents((prevTalents) =>
      result.reduce(
        (acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        },
        { ...prevTalents }
      )
    );
  };
  const loadJobInfo = async (roleIds: string[], projectIds: string[]) => {
    if (roleIds.length === 0 && projectIds.length === 0) return;

    const { roles, projects } = await jobsService.batchGetProjectRole({
      roleIds,
      projectIds,
    });

    if (roles) {
      setRoleNames((prevRoles) =>
        roles.reduce(
          (acc, cur) => {
            acc[cur.id] = cur.name;
            return acc;
          },
          { ...prevRoles }
        )
      );
    }
    if (projects) {
      setProjectTitles((prevProjects) =>
        projects.reduce(
          (acc, cur) => {
            acc[cur.id] = cur.title;
            return acc;
          },
          { ...prevProjects }
        )
      );
    }
  };
  const loadMore = async () => {
    const result: { data: Activity[]; key?: string } =
      await activityService.listHirerAgenda(authHirer.hirerId, type, dataKey);

    if (result.data.length > 0) {
      const roleIds = new Set<string>();
      const projectIds = new Set<string>();
      const talentIds = new Set<string>();
      result.data.forEach(({ projectId, roleId, talentId }) => {
        if (projectId && !projectTitles[projectId]) projectIds.add(projectId);
        if (!roleNames[roleId]) roleIds.add(roleId);
        if (!mappedTalents[talentId]) talentIds.add(talentId);
      });
      await loadTalentInfo(Array.from(talentIds));
      await loadJobInfo(Array.from(roleIds), Array.from(projectIds));
    }
    setData((prev) => prev.concat(result.data));
    setDataKey(result.key);
  };

  return (
    <Div className='agenda-tab'>
      <InfiniteScroll
        initLoading
        hasMore={dataKey !== ''}
        loadMore={loadMore}
        render={(setTriggerElement) => {
          const dataSource = data;
          const elements: React.ReactNode[] = [];

          dataSource.forEach((activity, index) => {
            if (!activity.scheduledDatetime) return;

            const currentSchMoment = moment(activity.scheduledDatetime);
            const currentDateStr = currentSchMoment.format('YYYY-MM-DD');

            let addDivider = true;
            if (index > 0 && dataSource[index - 1]?.scheduledDatetime) {
              const prevDateStr = moment(
                dataSource[index - 1].scheduledDatetime
              ).format('YYYY-MM-DD');

              addDivider = currentDateStr !== prevDateStr;
            }

            if (addDivider) {
              const now = moment(new Date()).format('YYYY-MM-DD');
              const dateDiff = moment(currentDateStr).diff(now, 'day');
              elements.push(
                <Div key={activity.scheduledDatetime}>
                  <Title level={5} style={{ margin: 0 }}>
                    {dateDiff === 0
                      ? 'Today, '
                      : dateDiff === 1
                      ? 'Tomorrow, '
                      : dateDiff === -1
                      ? 'Yesterday, '
                      : ''}
                    {currentSchMoment.format('DD MMM, ddd')}
                  </Title>
                </Div>
              );
            }

            elements.push(
              <div
                key={activity.activityId + index}
                ref={(el) =>
                  el && index === dataSource.length - 1
                    ? setTriggerElement(el)
                    : null
                }
              >
                <AgendaCard
                  activity={activity}
                  talent={mappedTalents[activity.talentId]}
                  projectTitle={
                    activity.projectId ? projectTitles[activity.projectId] : ''
                  }
                  roleName={roleNames[activity.roleId]}
                />
              </div>
            );
          });

          return <>{elements}</>;
        }}
        loadingIndicator={
          <Div flex>
            <Spin spinning style={{ margin: '0.5rem auto' }}></Spin>
          </Div>
        }
        endingIndicator={
          data.length === 0 ? (
            <Div className='zero-state'>
              <Empty description={<>No {type} agenda</>} />
            </Div>
          ) : (
            <Divider style={{ margin: '2rem auto' }}>
              That&apos;s all {type} agenda!
            </Divider>
          )
        }
      />
    </Div>
  );
};

export default AgendaTab;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ClockCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Div } from '../../../framework';
import { getApplicantWithdrawn } from '../../../helper/activity/getApplicantActivities';
import { activityActionType } from '../../../helper/constants';
import { Activity, Talent } from '../../../helper/type';
import { useActivityService } from '../../../service/activity.service';
import { RootState } from '../../../store/store';
import { setActivities } from '../../../store/hirer.slice';

import './AgendaCard.scss';

type Props = {
  activity: Activity;
  talent: Partial<Talent>;
  projectTitle: string;
  roleName: string;
};

const AgendaCard: FunctionComponent<Props> = ({
  activity,
  talent,
  projectTitle,
  roleName,
}) => {
  const dispatch = useDispatch();
  const activityService = useActivityService();

  const { mappedActivities } = useSelector((state: RootState) => state.hirer);
  const [withdraw, setWithdraw] = useState<Activity>();

  const scheduledDatetime = moment(activity?.scheduledDatetime);
  useEffect(() => {
    (async () => {
      const { roleId, talentId } = activity;
      if (
        !(roleId in mappedActivities) ||
        !(talentId in mappedActivities[roleId])
      ) {
        const { data } = await activityService.listRoleTalentActivity({
          roleId,
          talentId,
        });
        dispatch(setActivities({ roleId, talentId, activities: data }));
      }
    })();
  }, []);

  useEffect(() => {
    const { roleId, talentId } = activity;
    setWithdraw(getApplicantWithdrawn(roleId, talentId, mappedActivities));
  }, [mappedActivities]);

  return (
    <Div mv='l' className='agenda-card' flex>
      <Div className='talent-photo'>
        <img src={talent.profilePhotoThumbnail} />
      </Div>
      <Div className='schedule-info'>
        <Div className='agenda-card-header' flex>
          <Div>
            <span className='header-title'>
              {talent.name}
              {talent.dob &&
                `, ${
                  new Date().getFullYear() - new Date(talent.dob).getFullYear()
                }`}
            </span>
            <span className='header-subtitle'>
              {projectTitle} - {roleName}
            </span>
          </Div>
        </Div>

        <Div pr='m' className='project-details bold'>
          <Div pb='xs'>
            <ClockCircleOutlined className='icon' />
            <span className='scheduled-time'>
              {scheduledDatetime.fromNow()} (
              {scheduledDatetime.format('hh:mm a').toUpperCase()})
            </span>
          </Div>
          <Div pb='xs'>
            <VideoCameraOutlined className='icon' />
            <span className='activity-type'>
              {activity?.actionType ===
              activityActionType.talentAcceptConfirmation
                ? 'shooting'
                : activity?.actionType.toLowerCase()}
            </span>
          </Div>

          <Div flex>
            <Link to={`/t/${talent.id}`} className='view-profile-button'>
              View profile
            </Link>
          </Div>

          {withdraw && (
            <Div className='withdraw-reason' flex pv='s'>
              Withdrawal Reason:
              <br />
              {withdraw.withdrawReason}
            </Div>
          )}
        </Div>
      </Div>
    </Div>
  );
};

export default AgendaCard;
